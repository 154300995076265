import { render, staticRenderFns } from "./OragnizationStatistics.vue?vue&type=template&id=11d03fcc&scoped=true"
import script from "./OragnizationStatistics.vue?vue&type=script&lang=js"
export * from "./OragnizationStatistics.vue?vue&type=script&lang=js"
import style0 from "./OragnizationStatistics.vue?vue&type=style&index=0&id=11d03fcc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11d03fcc",
  null
  
)

export default component.exports